<template>
	<section>
		<div v-if="!showResult">
			<div v-if="presentation">
				<Presentation @goToStories="goToStories()" />
			</div>
			<div v-else>
				<component
					:is="stories[countStory].storyType"
					:data-id="stories[countStory].id"
					:infosStory="stories[countStory]"
					:countStory="countStory"
					@changeStory="goToNextStory()"
					@displayResults="displayResults()"
				/>
			</div>
		</div>
		<div v-else>
			<Results :stories="stories" />
		</div>
	</section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Presentation from '../presentation/Default';
import Results from '../results/Default';

export default {
	name: 'Default',
	components: {
		Presentation,
		Results,
	},
	computed: {
		...mapGetters('exercice', {
			stories: 'stories',
			questionsInStory: 'questionsInStory',
		}),
	},
	data() {
		return {
			countStory: 0,
			showResult: false,
			presentation: true,
		};
	},
	created() {
		this.$options.components[this.stories[this.countStory].storyType] = () =>
			import('../story-types/' + this.stories[this.countStory].storyType + '.vue');
	},
	methods: {
		...mapActions('exercice', ['getQuestionsStory']),
		goToNextStory() {
			this.countStory++;
			this.$options.components[this.stories[this.countStory].storyType] = () =>
				import('../story-types/' + this.stories[this.countStory].storyType + '.vue');
		},
		displayResults() {
			this.showResult = true;
			if (document.getElementById('menu-nav')) document.getElementById('menu-nav').style.display = 'block';
			if (document.getElementById('menu-nav-mobile'))
				document.getElementById('menu-nav-mobile').style.display = 'block';
		},
		goToStories() {
			this.presentation = false;
			// Nathan - comment 2 belowed because it hide nav in exercise page, dont know why we need to hide ??
			// if (document.getElementById('menu-nav')) document.getElementById('menu-nav').style.display = 'none';
			// if (document.getElementById('menu-nav-mobile')) document.getElementById('menu-nav-mobile').style.display = 'none';
		},
	},
};
</script>

<style></style>
